import Link from "next/link";
import Image from "next/image";

const Footer = () => {
  return (
    <div className="bg-zinc-800">
      <div className="mx-auto flex max-w-7xl flex-col justify-center px-8 pt-12 pb-6 font-kr text-zinc-100 md:px-16">
        <div className="flex flex-col justify-between md:flex-row md:space-x-16">
          <div className="block">
            <div className="w-36 pb-10 hover:cursor-pointer">
              <Link href="/">
                <img
                  src="/assets/ocena_logo_white.png"
                  className="w-full"
                  alt="ocena logo"
                />
              </Link>
            </div>
          </div>
          <div className="flex flex-col items-start justify-end space-y-5 md:flex-row md:space-y-0 md:space-x-12">
            <div className="flex w-1/2 flex-col">
              <h3 className="px-1 py-2 text-base font-medium">서비스</h3>
              <hr className="width-[1px] w-40 border-zinc-600 md:w-32"></hr>
              <div className="flex flex-col space-y-3 px-1 py-5">
                <Link href="/launch">
                  <a className="whitespace-nowrap font-light">
                    오세나 사용하기
                  </a>
                </Link>
                {/* <Link href="/pricing">
                  <a className="font-light">가격정책</a>
                </Link> */}
              </div>
            </div>
            {/* <div className="flex w-1/3 flex-col">
              <h3 className="px-1 py-2 text-base font-medium">소개</h3>
              <hr className="width-[1px] w-40 border-zinc-600 md:w-32"></hr>
              <div className="flex flex-col space-y-3 px-1 py-5">
                <Link href="/">
                  <a className="font-light">오세나</a>
                </Link>
              </div>
            </div> */}
            <div className="flex w-1/2 flex-col">
              <h3 className="px-1 py-2 text-base font-medium">팀</h3>
              <hr className="width-[1px] w-40 border-zinc-600 md:w-32"></hr>
              <div className="flex flex-col space-y-3 px-1 py-5">
                <Link href="/contact">
                  <a className="whitespace-nowrap font-light">문의하기</a>
                </Link>
                <a
                  href="https://www.linkedin.com/company/ocena-app"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="whitespace-nowrap font-light"
                >
                  링크드인
                </a>
                <Link href="https://blog.ocena.app" target="_blank">
                  <a className="whitespace-nowrap font-light">블로그</a>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex space-x-3 pt-16">
          <p className="text-xs font-light text-zinc-400">
            © Copyright 2023 ocena
          </p>
          <p className="text-xs font-light text-zinc-400">|</p>
          <a
            className="text-xs font-light text-zinc-400"
            href="mailto: contact@ocena.app"
          >
            contact@ocena.app
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
