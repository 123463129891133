import { useRouter } from "next/router";
import Head from "next/head";
import Footer from "../components/Footer";
import CTA from "../components/landingpage/CTA";
import Script from "next/script";
import Image from "next/image";
import Share from "../components/landingpage/Share";
import Link from "next/link";
import Navbar from "../components/Navbar";
import { useContext, useEffect, useState } from "react";

import { FirebaseContext } from "../contexts/FirebaseContext";

const Landingpage = () => {
  const router = useRouter();

  const { auth } = useContext(FirebaseContext);
  const [dataLoaded, setDataLoaded] = useState(false);

  // 로그인 되어있으면 대시보드로 옮겨주기
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      setDataLoaded(false);
      if (user && user.emailVerified && !user.displayName) {
        router.push("/signup/info");
      } else if (user && user.emailVerified) {
        router.push("/dashboard");
      }
      setDataLoaded(true);
    });
  }, []);

  // const LOGOS = [
  //   <img src="/assets/customer_logos/AK.png" alt="AK" className=" " />,
  //   <img
  //     src="/assets/customer_logos/stepping.png"
  //     alt="stepping"
  //     className=""
  //   />,
  //   <img
  //     src="/assets/customer_logos/indigo.png"
  //     alt="indigo"
  //     className=" pt-1"
  //   />,
  //   <img
  //     src="/assets/customer_logos/fleetune.png"
  //     alt="fleetune"
  //     className="w-[9rem] "
  //   />,
  //   <img
  //     src="/assets/customer_logos/jober.png"
  //     alt="jober"
  //     className="pt-1 "
  //   />,
  //   <img
  //     src="/assets/customer_logos/meissa.png"
  //     alt="meissa"
  //     className="h-9 "
  //   />,
  //   <img
  //     src="/assets/customer_logos/daytrip.png"
  //     alt="daytrip"
  //     className="h-9 pt-1"
  //   />,
  //   <img
  //     src="/assets/customer_logos/relate.png"
  //     alt="relate"
  //     className="h-7 "
  //   />,
  //   <img
  //     src="/assets/customer_logos/safetics.png"
  //     alt="safetics"
  //     className="h-7 "
  //   />,
  //   <img
  //     src="/assets/customer_logos/searchfi.png"
  //     alt="searchfi"
  //     className=" "
  //   />,
  //   <img src="/assets/customer_logos/2080.png" alt="2080" className=" " />,

  //   // <img
  //   //   src="/assets/customer_logos/tomuse.png"
  //   //   alt="내일의쓰임"
  //   //   className=" "
  //   // ></img>,
  // ];

  return (
    <>
      <Navbar />
      <Head>
        <title>ocena | 오세나</title>
        <link rel="icon" href="/assets/favicon.ico" />
      </Head>
      <Script
        id="prefinery-script"
        src="https://widget.prefinery.com/widget/v2/qtvascno.js"
        defer
      ></Script>

      {/* max-w-[83rem] */}
      <div className=" h-full w-full scroll-smooth bg-zinc-100 font-kr text-zinc-700 selection:bg-zinc-700 selection:text-zinc-100 ">
        <div className="mx-auto max-w-[83rem] items-center justify-between px-4 pt-14 md:px-8 md:pt-20 lg:flex lg:space-x-20 lg:pt-20">
          <div className="">
            <h1 className="whitespace-nowrap text-4xl font-bold leading-snug text-zinc-700 sm:text-[2.75rem] xl:text-5xl xl:leading-normal ">
              {/* <span className="underline decoration-primary/60 decoration-8 underline-offset-0 xl:decoration-[10px]">
                견적에서 매출까지
              </span> */}
              반복적인 영업지원
              <br /> 업무를 끝내세요
            </h1>
            <p className="mt-6 max-w-md whitespace-normal lg:text-lg">
              단 한 번의 거래 정보 입력으로 <br />
              <span className="font-bold">
                견적서, 세금계산서, 거래명세서, 정산관리{" "}
              </span>
              등 <br />
              번거로운 영업지원 업무를 한 번에 처리하세요.
            </p>
            <div className="relative mt-8 ">
              <div className="absolute inset-0 z-0 flex h-[4.5rem] w-[13rem] items-center justify-center rounded-full border-[3px] border-zinc-600 bg-zinc-600 "></div>
              <button
                onClick={(e) => {
                  router.push("/launch");
                }}
              >
                <div className="z-100 flex h-[4.5rem] w-[13rem] items-center justify-center rounded-full border-[3px] border-zinc-700 bg-white text-lg font-bold transition-all duration-150 ease-in-out will-change-transform hover:translate-y-[-4px] hover:translate-x-[-2px] focus:translate-y-[-1px] focus:translate-x-[-1px] focus:bg-zinc-300 active:duration-100 ">
                  정식 출시 사전신청
                </div>
              </button>
            </div>
          </div>
          <img
            src="/assets/new_hero-2.png"
            alt="오세나 전자견적서"
            className="my-auto mt-16 w-full overflow-auto md:max-w-3xl lg:mt-0 "
          ></img>
        </div>

        {/* 로고 배너 */}

        {/* <div className="w-full ">
          <div className="relative mx-auto mt-24 w-full max-w-5xl overflow-hidden  before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[60px] before:bg-gradient-to-r before:from-zinc-100 before:via-zinc-100 before:to-zinc-100/5 before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[60px] after:-scale-x-100 after:bg-gradient-to-r after:from-zinc-100 after:via-zinc-100 after:to-zinc-100/5 after:content-[''] md:before:w-[130px] md:after:w-[130px]">
            <div className="flex w-[360rem] animate-infinite-scroll space-x-20 py-8">
              {LOGOS.map((logo, index) => (
                <div
                  className="slide flex w-[125px] items-center justify-center"
                  key={index}
                >
                  {logo}
                </div>
              ))}
              {LOGOS.map((logo, index) => (
                <div
                  className="slide  flex w-[125px] items-center justify-center"
                  aria-hidden="true"
                  key={index}
                >
                  {logo}
                </div>
              ))}
            </div>
          </div>
        </div> */}

        <div className="mx-auto w-full max-w-6xl px-4 py-24 md:px-6">
          <div className="inline-flex w-full flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
            <ul className="flex animate-infinite-scroll items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-h-10 [&_img]:max-w-[10rem]">
              {/* {LOGOS.map((logo, index) => (
                <li className="" key={index}>
                  {logo}
                </li>
              ))} */}
              <li>
                <img
                  src="/assets/customer_logos/AK.png"
                  alt="AK"
                  className=" "
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/stepping.png"
                  alt="stepping"
                  className=""
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/indigo.png"
                  alt="indigo"
                  className=" pt-1"
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/fleetune.png"
                  alt="fleetune"
                  className="w-[9rem] "
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/jober.png"
                  alt="jober"
                  className="pt-1 "
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/meissa.png"
                  alt="meissa"
                  className="h-9 "
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/daytrip.png"
                  alt="daytrip"
                  className="h-9 pt-1"
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/relate.png"
                  alt="relate"
                  className="h-7 "
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/safetics.png"
                  alt="safetics"
                  className="h-7 "
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/searchfi.png"
                  alt="searchfi"
                  className=" "
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/2080.png"
                  alt="2080"
                  className=" "
                />
              </li>
            </ul>
            <ul
              className="flex animate-infinite-scroll items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-h-10 [&_img]:max-w-[10rem]"
              aria-hidden="true"
            >
              {/* {LOGOS.map((logo, index) => (
                <li className="" key={index}>
                  {logo}
                </li>
              ))} */}
              <li>
                <img
                  src="/assets/customer_logos/AK.png"
                  alt="AK"
                  className=" "
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/stepping.png"
                  alt="stepping"
                  className=""
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/indigo.png"
                  alt="indigo"
                  className=" pt-1"
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/fleetune.png"
                  alt="fleetune"
                  className="w-[9rem] "
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/jober.png"
                  alt="jober"
                  className="pt-1 "
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/meissa.png"
                  alt="meissa"
                  className="h-9 "
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/daytrip.png"
                  alt="daytrip"
                  className="h-9 pt-1"
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/relate.png"
                  alt="relate"
                  className="h-7 "
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/safetics.png"
                  alt="safetics"
                  className="h-7 "
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/searchfi.png"
                  alt="searchfi"
                  className=" "
                />
              </li>

              <li>
                <img
                  src="/assets/customer_logos/2080.png"
                  alt="2080"
                  className=" "
                />
              </li>
            </ul>
          </div>
        </div>

        {/* <div className="mt-32 w-full flex-col">
            <h2 className="text-center text-2xl font-bold lg:text-4xl">
              <span className="underline decoration-primary/60 decoration-8 underline-offset-0">
                세일스보드
              </span>
            </h2>
            <p className="mt-4 text-center text-sm lg:text-base">
              한 개의 세일스보드 안에서 견적에서 매출까지의 과정을 관리하세요.{" "}
            </p>
            <img
              src="/assets/sales-process.png"
              alt="오세나 세일스보드 적용 영업 프로세스"
              className="mt-6"
            ></img>
          </div> */}

        <div className=" bg-zinc-100 px-4 pt-20 pb-40 md:px-8">
          <div className="mx-auto  flex flex-col items-center">
            <h2 className="whitespace-normal text-center text-3xl font-bold leading-normal text-zinc-700 md:whitespace-nowrap md:text-4xl lg:text-5xl ">
              번거로운 영업지원 <br className="md:hidden" /> 업무가 간단해집니다
            </h2>
            <p className="mt-6 text-center text-base font-normal text-zinc-400 md:text-2xl">
              같은 정보를 반복해서 입력할 필요 없어요
            </p>
          </div>
          <div className="mx-auto mt-28 max-w-[72rem] space-y-40">
            <div className="flex flex-col">
              <div className=" justify-between lg:flex lg:space-x-32">
                <img
                  src="/assets/home-quote.png"
                  alt="오세나 견적서"
                  className="overflow-auto lg:max-w-2xl xl:max-w-3xl 2xl:max-w-4xl"
                ></img>
                <div className="w-full flex-col items-start justify-center lg:mr-8 lg:flex ">
                  <h2 className="mt-6 whitespace-nowrap text-3xl font-bold leading-snug lg:text-4xl lg:leading-[3rem]">
                    쉽고 빠르게 <br /> 견적서를 만드세요
                    {/* <span className="underline decoration-primary/60 decoration-8 underline-offset-0">
                      전자 견적서
                    </span> */}
                  </h2>
                  <p className="mt-8 text-lg">
                    자주 사용하는 프로덕트와 템플릿 저장해 복잡하고
                    <br className="hidden lg:block" /> 번거로운 견적서를 쉽고
                    빠르게 만들어보세요.
                  </p>
                  {/* <ul className="mt-4 ml-4 list-outside list-disc space-y-3 marker:text-primary/80">
                    <li className="">프로덕트 / 템플릿 저장</li>
                    <li className="">로고, 도장</li>
                    <li className="">구독, 할인, 기간별 거래 조건 변경</li>
                  </ul> */}
                  <Link href="/launch">
                    <button className="group mt-8 flex items-center space-x-2 text-lg font-medium hover:space-x-3  ">
                      <p className=" underline decoration-primary/60 decoration-[3px] underline-offset-4 transition-all group-hover:no-underline">
                        더 알아보기
                      </p>
                      <span className="no-underline  transition-all">→</span>
                    </button>
                  </Link>
                </div>
              </div>
              {/* <div className="mt-6 flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
                <div className="rounded-[2.25rem] border-2 border-zinc-200 p-8 py-10 duration-[400ms] hover:border-zinc-400 hover:bg-white lg:w-1/3">
                  <h3 className="text-xl font-bold">간편한 견적 제작</h3>
                  <p className="mt-5 ">
                    프로덕트를 미리 등록해 인적 오류를 줄이면서도 빠르고 간편하게
                    견적서를 만드세요.
                  </p>
                </div>
                <div className="rounded-[2.25rem] border-2 border-zinc-200 p-8 py-10 duration-[400ms] hover:border-zinc-400 hover:bg-white lg:w-1/3">
                  <h3 className="text-xl font-bold">버전 히스토리</h3>
                  <p className="mt-5 ">
                    견적 변경사항을 버전 히스토리 기능을 통해 쉽게 확인할 수
                    있습니다.
                  </p>
                </div>
                <div className="rounded-[2.25rem] border-2 border-zinc-200 p-8 py-10 duration-[400ms] hover:border-zinc-400 hover:bg-white lg:w-1/3">
                  <h3 className="text-xl font-bold">견적 확정</h3>
                  <p className="mt-5 ">
                    고객은 클릭 한번으로 간편하게 견적 내역을 확정지을 수
                    있습니다.
                  </p>
                </div>
              </div> */}
            </div>

            <div className=" justify-between lg:flex lg:space-x-32">
              <img
                src="/assets/home-billing.png"
                alt="오세나 정산관리"
                className="overflow-auto lg:max-w-2xl xl:max-w-3xl 2xl:max-w-4xl"
              ></img>
              <div className="w-full flex-col items-start justify-center lg:mr-8 lg:flex ">
                <h2 className="mt-6 whitespace-nowrap text-3xl font-bold leading-snug lg:text-4xl lg:leading-[3rem]">
                  복잡한 거래 조건에 대한 <br /> 정산 관리를 쉽게 처리하세요{" "}
                  {/* <span className="underline decoration-primary/60 decoration-8 underline-offset-0">
                    전자 견적서
                  </span> */}
                </h2>
                <p className="mt-8 text-lg">
                  저장된 거래 정보와 정산 조건에 따라
                  <br className="hidden lg:block" /> 청구 내역이 자동으로
                  생성됩니다.
                </p>
                <Link href="/launch">
                  <button className="group mt-8 flex items-center space-x-2 text-lg font-medium hover:space-x-3  ">
                    <p className=" underline decoration-primary/60 decoration-[3px] underline-offset-4 transition-all group-hover:no-underline">
                      더 알아보기
                    </p>
                    <span className="no-underline  transition-all">→</span>
                  </button>
                </Link>
              </div>
            </div>

            <div className=" justify-between lg:flex lg:space-x-32">
              <img
                src="/assets/home-tax.png"
                alt="오세나 전자 세금계산서"
                className="overflow-auto lg:max-w-2xl xl:max-w-3xl 2xl:max-w-4xl"
              ></img>
              <div className="w-full flex-col items-start justify-center lg:mr-8 lg:flex ">
                <h2 className="mt-6 whitespace-nowrap text-3xl font-bold leading-snug lg:text-4xl lg:leading-[3rem]">
                  클릭 한번으로 <br /> 세금계산서를 발행하세요
                </h2>
                <p className="mt-8 text-lg">
                  저장된 정산 내역에 따라
                  <br className="hidden lg:block" />
                  세금계산서가 자동완성됩니다.
                </p>
                <Link href="/launch">
                  <button className="group mt-8 flex items-center space-x-2 text-lg font-medium hover:space-x-3  ">
                    <p className=" underline decoration-primary/60 decoration-[3px] underline-offset-4 transition-all group-hover:no-underline">
                      더 알아보기
                    </p>
                    <span className="no-underline  transition-all">→</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* End of section */}

        {/* Quote */}

        <div className="bg-white">
          <div className="mx-auto flex max-w-[72rem] flex-col items-center justify-center space-y-14 py-28 px-4 lg:flex-row lg:space-y-0 lg:space-x-20 lg:py-32 lg:px-8">
            <div className="whitespace-normal px-4 text-center text-2xl font-bold leading-normal text-zinc-700  lg:whitespace-nowrap">
              &quot;오세나를 통해 번거롭지만 필수적인 영업 지원 업무를&nbsp;
              <br className="hidden md:block " />
              <span className="underline decoration-primary/60 decoration-[3px] underline-offset-4">
                한 번에 처리
              </span>
              할 수 있어 업무 효율을 높일 수 있었습니다.&quot;
            </div>
            <img className="h-20 w-auto" src="/assets/indigo_logo.png"></img>
          </div>
        </div>

        {/* 카테고리 */}

        {/* <div className="mx-auto max-w-[80rem] px-4 md:px-8">
          <h2 className="mt-36 text-3xl font-bold leading-snug">
            B2B 세일스를 위한&nbsp;
            <span className="underline decoration-zinc-300 decoration-8 underline-offset-0">
              에센셜 영업지원 툴킷
            </span>
          </h2>
          
          <div className="mt-6 flex-col rounded-3xl border-2 border-zinc-300 bg-zinc-200 px-4 py-8 md:p-12">
            <div className="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
              <div className="relative lg:w-1/3">
                <div className="absolute inset-0 z-0 rounded-[2.25rem] bg-zinc-700"></div>

                <div className="z-100 h-full w-full rounded-[2.25rem] border-2 border-zinc-700 bg-white p-8 py-10 transition-all duration-150 ease-in-out will-change-transform hover:translate-y-[-6px] hover:translate-x-[-4px] active:duration-100 ">
                  <h3 className="text-2xl font-bold">전자 견적서</h3>
                  <p className="mt-5 ">
                    제작, 공유, 관리가 모두 간편한 전자견적서를 통해 빠르게
                    거래를 성사시키세요.
                  </p>
                  <Link href="/betasignup">
                    <button className="mt-6">더 알아보기 →</button>
                  </Link>
                </div>
              </div>
              <div className="relative lg:w-1/3">
                <div className="absolute inset-0 z-0 rounded-[2.25rem] bg-zinc-700"></div>
                <div className="z-100 h-full w-full rounded-[2.25rem] border-2 border-zinc-700 bg-white p-8 py-10 transition-all duration-150 ease-in-out will-change-transform hover:translate-y-[-6px] hover:translate-x-[-4px] active:duration-100 ">
                  <h3 className="text-2xl font-bold">전자 세금계산서 / 계약</h3>
                  <p className="mt-5 ">
                    견적 내역을 기반으로 영업 관련 문서를 간편하게 생성하고
                    관리하세요.
                  </p>
                  <Link href="/betasignup">
                    <button className="mt-6">더 알아보기 →</button>
                  </Link>
                </div>
              </div>
              <div className="relative lg:w-1/3">
                <div className="absolute inset-0 z-0 rounded-[2.25rem] bg-zinc-700"></div>

                <div className="z-100 h-full w-full rounded-[2.25rem] border-2 border-zinc-700 bg-white p-8 py-10 transition-all duration-150 ease-in-out will-change-transform hover:translate-y-[-6px] hover:translate-x-[-4px] active:duration-100 ">
                  <h3 className="text-2xl font-bold">정산 관리</h3>
                  <p className="mt-5 ">
                    세금계산서와 계좌 내역 연동을 통해 가장 쉽고 정확하게 정산
                    관리를 할 수 있습니다.
                  </p>
                  <Link href="/betasignup">
                    <button className="mt-6">더 알아보기 →</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* 카테고리 끝 */}

        {/* <div id="세금계산서"></div>
          <div className="flex w-full flex-col-reverse justify-start lg:mr-4 lg:flex lg:flex-row lg:items-center lg:justify-between lg:space-x-8 ">
            <div className="flex flex-col">
              <h2 className="mt-6 whitespace-nowrap text-3xl font-bold leading-snug lg:text-4xl lg:leading-[3rem]">
                <span className="underline decoration-primary/60 decoration-8 underline-offset-0">
                  전자 세금계산서
                </span>
                를 <br className="hidden lg:block" /> 자동으로
              </h2>
              <p className="mt-6 lg:whitespace-nowrap">
                견적서의 거래 내역을 불러와 수기 입력 과정 없이{" "}
                <br className="hidden lg:block" />
                자동으로 전자 세금계산서를 발행합니다.{" "}
              </p>
            </div>
            <img
              src="/assets/electronic_tax_form.png"
              alt="오세나 전자 세금계산서"
              className="w-full overflow-auto lg:max-w-2xl xl:max-w-3xl 2xl:max-w-4xl"
            ></img>
          </div> */}
        {/* <div className="mt-28 flex w-full flex-col-reverse justify-start lg:mr-4 lg:flex lg:flex-row-reverse lg:items-center lg:justify-between ">
            <div className="  flex flex-col lg:ml-12">
              <h2 className="mt-6 text-3xl font-bold leading-snug lg:text-4xl lg:leading-[3rem]">
                <span className="underline decoration-primary/60 decoration-8 underline-offset-0">
                  전자 계약
                </span>
                &nbsp;연동
              </h2>
              <p className="mt-6 lg:whitespace-nowrap">
                모두싸인 연동을 통해 전자서명 기능이 제공됩니다.
                <br className="hidden lg:block" /> 기존에 사용하던 전자계약서를
                그대로 사용하세요.{" "}
              </p>
            </div>
            <img
              src="/assets/e-contract.png"
              alt="오세나 전자계약 전자서명"
              className="w-full overflow-auto lg:max-w-2xl xl:max-w-3xl 2xl:max-w-4xl"
            ></img>
          </div> */}

        {/* <div id="정산관리"></div>
          <div className=" flex flex-col">
            <div className=" lg:flex lg:flex-row-reverse lg:justify-between">
              <img
                src="/assets/billing_managemet.png"
                alt="오세나 정산관리"
                className="w-full overflow-auto lg:max-w-2xl xl:max-w-3xl 2xl:max-w-4xl"
              ></img>
              <div className=" flex-col items-start justify-center lg:mr-8 lg:flex ">
                <h2 className="mt-10 whitespace-nowrap text-3xl font-bold leading-snug lg:text-4xl lg:leading-[3rem]">
                  더 이상{" "}
                  <span className="underline decoration-primary/60 decoration-8 underline-offset-0">
                    미수금
                  </span>
                  이&nbsp;
                  <br />
                  누락될 일이 없습니다
                </h2>
                <p className="mt-6">
                  세금계산서와 계좌 내역 연동을 통해 가장{" "}
                  <br className="hidden lg:block" /> 쉽고 정확하게 정산 관리를
                  할 수 있습니다.
                </p>
              </div>
            </div>
            <div className="mt-6 flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
              <div className=" rounded-[2.25rem] border-2  border-zinc-200 p-8 py-10 transition-all duration-[400ms] hover:border-zinc-400 hover:bg-white lg:w-1/3">
                <h3 className="text-xl font-bold">입금 확인</h3>
                <p className="mt-5 ">
                  세금계산서와 계좌 내역을 비교해 거래별 입금 내역을 확인이
                  가능합니다.
                </p>
              </div>
              <div className="rounded-[2.25rem] border-2 border-zinc-200 p-8 py-10 duration-[400ms] hover:border-zinc-400 hover:bg-white lg:w-1/3">
                <h3 className="text-xl font-bold">대금 지급 기한 알림</h3>
                <p className="mt-5 ">
                  입금 기한이 되면 결제 여부를 확인하고 액션을 취할 수 있도록
                  알림을 보내드립니다.
                </p>
              </div>
              <div className="rounded-[2.25rem] border-2 border-zinc-200 p-8 py-10 duration-[400ms] hover:border-zinc-400 hover:bg-white lg:w-1/3">
                <h3 className="text-xl font-bold">정산 요청 메일 전송</h3>
                <p className="mt-5 ">
                  껄끄러운 정산 요청을 오세나에서 알림 메일로 대신해드립니다.
                </p>
              </div>
            </div>
          </div> */}

        {/* <h2
            id="사전신청"
            className="my-40 whitespace-nowrap pl-8 text-3xl font-bold leading-normal lg:text-5xl lg:leading-[4.5rem]"
          >
            고객에 집중하세요.
            <br />
            <br /> 영업지원 업무는 <br />
            <span className="underline decoration-primary/60 decoration-8 underline-offset-0">
              오세나
            </span>
            가 해결합니다.
          </h2> */}
        {/* <div id="사전신청"> </div>
        <div className="mt-16 flex flex-col rounded-3xl border-4 border-zinc-700 bg-white p-10 lg:flex-row lg:justify-between lg:px-20 lg:py-20 xl:px-24">
          <div className="flex flex-col justify-between lg:mr-8">
            <div className="">
              <p className="mb-2 font-bold text-primary/80">7월 출시 예정</p>
              <h2 className="whitespace-nowrap text-3xl font-bold leading-normal lg:text-4xl lg:leading-[3.5rem]">
                <span className="underline decoration-primary/60 decoration-8 underline-offset-0">
                  사전신청
                </span>
                하고 <br />
                가장 먼저 사용하세요
              </h2>
            </div>
            <div className=" my-8">
              <h3 className="font-bold text-zinc-400">사전신청 혜택</h3>
              <div className="mt-4 grid grid-cols-2 grid-rows-2 gap-4 font-medium md:gap-6 md:gap-x-14">
                <p className="">유료플랜 1개월 무료</p>
                <p className="">얼리 엑세스</p>
                <p className="">전용 특별 가격정책</p>
                <p className="">1:1 온보딩</p>
              </div>
            </div>
          </div>
          <div className="mt-16 w-full lg:mt-0 lg:w-2/5">
            <div className="">
              <div className="prefinery-form-embed "></div>
            </div>
          </div>
        </div> */}
        <div className="mx-auto mt-32 max-w-[72rem] px-4 pb-20 md:px-8">
          <CTA />
        </div>
        {/* <div className="py-20">
          <div className="items-center justify-between rounded-[2rem] border-2 border-zinc-300 p-10 lg:flex lg:py-16 lg:px-20">
            <div className="flex-col">
              <p className="mb-2">출시 전까지</p>
              <h2 className="whitespace-nowrap text-xl font-bold leading-snug lg:text-2xl">
                무료 PDF 견적서
                <br />
                제작 툴 사용하기
              </h2>
            </div>

            <div className="relative mt-8 ">
              <div className="absolute inset-0 z-0 flex h-[4.75rem] w-52 items-center justify-center rounded-[1.75rem] border-[3px] border-zinc-700 bg-zinc-700 "></div>
              <Link href="/pdf">
                <button
                  href="#사전신청"
                  className="z-100 flex h-[4.75rem] w-52 items-center justify-center rounded-[1.75rem] border-[3px] border-zinc-700 bg-white text-lg font-bold transition-all duration-150 ease-in-out will-change-transform hover:translate-y-[-3px] hover:translate-x-[-2px] focus:translate-y-[-1px] focus:translate-x-[-1px] focus:bg-zinc-300 active:duration-100 "
                >
                  견적서 만들기
                </button>
              </Link>
            </div>
          </div>
        </div> */}
      </div>

      <Footer />
    </>
  );
};

export default Landingpage;
