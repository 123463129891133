import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LandingAnimation from "./LandingAnimation";

const Share = () => {
  const shareKakao = () => {
    window.Kakao.Share.sendCustom({
      templateId: 84118,
    });
  };
  return (
    <LandingAnimation>
      <div>
        <div className="flex justify-center pb-8">
          <div className="m-4 flex min-h-[350px] flex-col justify-center space-y-16 rounded-md bg-primary/80 p-10 md:m-10 md:max-w-5xl md:flex-row md:space-x-16 md:space-y-0 md:p-12">
            <h2 className="font-kr text-4xl font-bold leading-normal text-zinc-900 md:basis-[45%]">
              오세나가 필요한 지인이 있나요?
            </h2>
            <div className="md: flex basis-[65%] flex-col justify-between space-y-6 md:p-1">
              <p className="font-kr text-lg font-normal leading-normal text-zinc-900 md:content-start lg:text-xl">
                지인의 업무효율을 한단계 업그레이드 시켜줄 오세나 견적서 제작
                툴을 소개해주세요.
              </p>
              <div className="flex space-x-4">
                <button
                  className="flex h-11 w-11 items-center justify-center rounded-full bg-zinc-800"
                  onClick={() => {
                    navigator.clipboard.writeText("https://ocena.app");
                    toast("링크가 복사되었습니다.", {
                      toastId: "오세나 LINK_COPIED",
                    });
                  }}
                >
                  <img
                    src="/assets/link_icon.png"
                    className="w-7"
                    alt="copy link icon"
                  />
                </button>
                <button
                  onClick={shareKakao}
                  className="flex h-11 w-11 items-center justify-center rounded-full bg-zinc-800"
                >
                  <img
                    src="/assets/kakao_logo.png"
                    className="w-6"
                    alt="share to Kakao Talk icon"
                  />
                </button>
                <a
                  href="https://www.linkedin.com/company/ocena-app"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex h-11 w-11 items-center justify-center rounded-full bg-zinc-800"
                >
                  <img
                    src="/assets/linkedin_logo.png"
                    className="w-5"
                    alt="share to Linkedin icon"
                  />
                </a>
                <a
                  href="https://www.instagram.com/ocena.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex h-11 w-11 items-center justify-center rounded-full bg-zinc-800"
                >
                  <img
                    src="/assets/instagram_logo.png"
                    className="w-6"
                    alt="link_icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingAnimation>
  );
};

export default Share;
