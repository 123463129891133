import { useRef } from "react";
import {motion, useInView} from "framer-motion"

const LandingAnimation = ({children}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    
    return(
        <motion.div ref={ref}
            style={{
                transform: isInView ? "none" : "translateY(50px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
            }}
        >
            {children}
        </motion.div>
    )
}

export default LandingAnimation