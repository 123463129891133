import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LandingAnimation from "./LandingAnimation";
import Link from "next/link";

const CTA = () => {
  return (
    // <LandingAnimation>
    <>
      <div className="relative">
        <div className="absolute inset-0 z-0 h-56 w-full rounded-[1.75rem] border-[3px] border-zinc-700 bg-zinc-700 sm:h-64 md:h-72 "></div>
        <div className="z-100 flex h-56 w-full translate-y-[-5px] translate-x-[-5px] flex-col justify-between rounded-[1.75rem] border-[3px] border-zinc-700 bg-white p-8 text-lg font-bold sm:h-64 sm:px-10 sm:pb-10 sm:pt-12 md:h-72 md:pt-16 ">
          <h2 className="text-xl font-bold sm:text-2xl md:text-3xl lg:text-4xl">
            <span className=" underline decoration-primary/60 decoration-4 underline-offset-0 md:decoration-8">
              오세나
            </span>
            와 함께 반복적인 <br className=" md:hidden" />
            영업지원 업무를 끝내세요
          </h2>
          <div className="flex w-full justify-end space-x-1">
            <Link className="" href="/contact">
              <button className="hidden rounded-full border-[3px] border-zinc-100 px-5 py-4 text-sm font-bold transition-all hover:border-zinc-500 hover:bg-zinc-100 sm:block md:px-7 md:text-base">
                데모 요청하기
              </button>
            </Link>
            <Link href="/launch">
              <button className="rounded-full border-[3px] border-primary/80 px-5 py-4 text-sm font-bold transition-all hover:border-primary/10 hover:bg-primary/50  md:px-7 md:text-base">
                출시 사전신청
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
    // </LandingAnimation>
  );
};

export default CTA;
